(function ($) {
  'use strict';

  window.DGS.Components = window.DGS.Components || {};
  window.DGS.Components.ImageSpot = window.DGS.Components.ImageSpot || {};
  window.DGS.Components.ImageSpot.ShouldEqualizeHeader = function () {
    return true;
  };
  window.DGS.Components.ImageSpot.ShouldEqualizeText = function () {
    return true;
  };
  window.DGS.OnLoad.getInstance().register(function () {
    $('.image-spot').each(function () {
      if (window.DGS.Components.ImageSpot.ShouldEqualizeHeader($(this))) {
        window.DGS.EqualHeight.getInstance().register($('.image-spot-element-header', $(this)));
      }
      if (window.DGS.Components.ImageSpot.ShouldEqualizeText($(this))) {
        window.DGS.EqualHeight.getInstance().register($('.image-spot-element-text', $(this)));
      }
    });
  });
})(jQuery);