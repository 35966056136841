(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    $(".component.rich-text.overlay").each(function () {
      var $this = $(this),
        $componentAbove = $this.prev();
      $componentAbove.add($this).hover(function () {
        $this.addClass("active");
      }, function () {
        $this.removeClass("active");
      });
    });
  });
})(jQuery, window.DGS);