/**
 * Created by mady on 20-06-2017.
 */
(function ($, DGS) {
  function checkForMobileImage() {
    $(".intro-banner").each(function () {
      var $this = $(this);
      var $bannerImage = $(".intro-banner-image", $this);
      var hasMobileImage = $bannerImage.data("mobileImage") !== undefined;
      if (hasMobileImage) {
        var $img = $("img", $this);
        if (window.innerWidth < 768) {
          if ($bannerImage.data("desktopImage") === undefined) {
            // preserve our desktop-image data

            var imgurl = $img.attr("src");
            // cleans url so it doesn't re-download it if the user goes back to a non-mobile breakpoint
            imgurl = imgurl.split("?")[0].split("#")[0];
            $bannerImage.data("desktopImage", imgurl);
            $bannerImage.data("desktopImageHeight", $img.attr("height"));
            $bannerImage.data("desktopImageWidth", $img.attr("width"));
            $bannerImage.data("desktopImageAltText", $img.attr("alt"));
          }
          $img.attr("height", $bannerImage.data("mobileImageHeight"));
          $img.attr("width", $bannerImage.data("mobileImageWidth"));
          $img.attr("alt", $bannerImage.data("mobileImageAltText"));
          $img.attr("src", $bannerImage.data("mobileImage"));
        } else if ($bannerImage.data("desktopImage") !== undefined) {
          $img.attr("height", $bannerImage.data("desktopImageHeight"));
          $img.attr("width", $bannerImage.data("desktopImageWidth"));
          $img.attr("alt", $bannerImage.data("desktopImageAltText"));
          $img.attr("src", $bannerImage.data("desktopImage"));
        }
      }
    });
  }
  DGS.Resizer.getInstance().register(function () {
    checkForMobileImage();
    var $this,
      $outroPosWrapper,
      $innerPosWrapper,
      $stickyLink,
      stickyLinkHeight = 0;
    $(".component.intro-banner").each(function () {
      $this = $(this);
      $outroPosWrapper = $(".outer-pos-wrapper", $this);
      $innerPosWrapper = $(".inner-pos-wrapper", $this);
      $stickyLink = $(".sticky-downlink a", $this);
      if ($stickyLink.length) {
        stickyLinkHeight = $stickyLink.outerHeight();
      }
      $this.removeClass("content-overflow");
      if ($innerPosWrapper.outerHeight() >= $outroPosWrapper.outerHeight() - stickyLinkHeight) {
        $this.addClass("content-overflow");
      }
    });
  });
})(jQuery, window.DGS);