// eslint-disable-next-line wrap-iife
(function anon($, DGS) {
  var _this = this;
  var burgerMenuConfig = {
    expanderSelector: '.header-mobile .nav-expander',
    groupTwoLastLevels: false
  };
  var megaMenuConfig = {
    hover: true
  };
  DGS.OnLoad.getInstance().register(function () {
    var sectionShown;
    var $logo = $('#header .image.logo-left');
    var $logoRight = $('#header .image.logo-right');
    var $menu = $('#header .navigation');
    var $mainContainer = $('#wrapper > #header');
    var $headerTopbar = $('#header .contact-bar');
    var headerSettings = {
      mainContainer: $mainContainer,
      determineSectionCallback: function determineSectionCallback(section) {
        sectionShown = section;
        return null;
      },
      sections: {
        mobile: {
          containers: [{
            className: 'header-mobile-topbar',
            components: [$headerTopbar]
          }, {
            className: 'header-mobile',
            components: [$logo, $logoRight, $menu, $('<div class="nav-expander"><span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span></div>')]
          }]
        },
        desktop: {
          containers: [{
            className: 'header-desktop-topbar',
            containers: [{
              className: 'row-1',
              components: [$headerTopbar]
            }]
          }, {
            className: 'header-desktop',
            containers: [{
              className: 'row-1',
              components: [$logo, $menu, $logoRight]
            }]
          }]
        }
      }
    };

    // BUILD MARKUP
    DGS.Builder.build(headerSettings);
    DGS.Event.getInstance().on(DGS.Events.BUILDER_DONE, function (settings) {
      if (settings !== headerSettings) return; // ONLY RUN POST SETUP IF IT'S THE RIGHT BUILDER

      $('#header').addClass('animate');

      // SETUP MEGA MENU
      $('.desktop-section .navigation .component-content > ul').megaMenu(megaMenuConfig);

      // SETUP BURGER MENU
      var backButton = '<div class="nav-back">Back</div>';
      $('.mobile-section .navigation').before(backButton);
      $('.mobile-section .navigation .component-content > ul').burgerMenu(burgerMenuConfig);

      // REGISTER RESIZER HANDLER
      DGS.Resizer.getInstance().register(function () {
        // ADJUST CONTENT SECTION BY ADDING BOTTOM MARGIN TO HEADER
        var headerHeight = 0;
        if (sectionShown === DGS.DESKTOP_LAYOUT) {
          headerHeight = $('#header .desktop-section').outerHeight();
        } else if (sectionShown === DGS.MOBILE_LAYOUT) {
          headerHeight = $('#header .mobile-section').outerHeight();
          $('.dgs-burger-menu').css('top', headerHeight);
          $('.nav-back').css('top', headerHeight);
        }
      });
    });

    // Prevent browser from showing tooltip with title attributes
    var $attr = $('[title]');
    $attr.hover(function () {
      var title = $(_this).attr('title');
      $(_this).attr('temp-title', title);
      $(_this).attr('title', '');
    });
    $attr.click(function () {
      var title = $(_this).attr('temp-title');
      $(_this).attr('title', title);
    });
    // Prevent browser from showing tooltip with title attributes - end
  });
})(jQuery, window.DGS);