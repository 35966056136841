// eslint-disable-next-line func-names
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    if ($('.component.form').length) {
      var options = {
        moveAsteriskToLabel: true
      };
      $('.component.form').wffm(options);
      $('.scfForm').append($('.scfFooterBorder'));
      window.DGS.Event.getInstance().trigger(window.DGS.Events.SELECT_ADDED, $('.component.form select'));
    }
  });
})(jQuery);